import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import JSLogo from '../images/js-logo.svg'
import Layout from '../components/layout'
import Seo from '../components/seo'

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '28.5% 0px',
      }}
    >
      <a
        class="hvr-grow"
        href="http://learn.frontendverse.com"
        style={{ color: 'white', textDecoration: 'none' }}
        title="Go to JS verse"
      >
        <div
          // class="frontendverse-gradient"
          style={{
            borderRadius: '2px',
            height: '150px',
            width: '250px',
            background: '#',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: '1px solid #939300',
            // backgroundColor: '#007E86',
          }}
        >
          <img
            src={JSLogo}
            alt="JSLogo"
            height="40"
            width="40"
            style={{ borderRadius: '4px' }}
          />
        </div>
      </a>
    </div>
  </Layout>
)

export default IndexPage
